<!-- 客户绑定司机设置 -->
<template>
  <div>
    <div style="display: flex;justify-content: space-between;">
      <div>
        <span style="font-size: 24px;font-weight: 500;color: #707070;">客户绑定司机设置</span>
      </div>
      <div>
        <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
      </div>
    </div>

    <el-card shadow="never" style="margin-top: 20px;">
      <el-form
        ref="form"
        :model="form"
        label-width="100px">

        <el-row :gutter="15" style="padding-left: 20px;padding-right: 20px;">
          <div style="display: flex;align-items: center;">
            <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
            <span style="font-size: 20px;font-weight: 700;color: #202033;">客户名称：中华联合保险四川公司</span>
          </div>
          <div style="display: flex;align-items: center;margin-top: 20px;">
            <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
            <span style="font-size: 20px;font-weight: 700;color: #202033;">接单司机</span>
            <span style="margin-left: 20px;">
               <el-checkbox v-model="form.checked">司机全选</el-checkbox>
            </span>
          </div>
          <div style="margin-top: 10px;" v-for="item in 4">
            <div style="display: inline-block; margin-right: 50px;">
              <el-checkbox v-model="form.checked">自营司机XXXXXX</el-checkbox>
            </div>
            <div style="display: inline-block;margin-right: 50px;">
              <span style="margin-right: 20px;">服务区域</span>
              <el-cascader
                v-model="value"
                :options="options"
                @change="handleChange"></el-cascader>
            </div>
            <div style="display: inline-block;">
              <span style="margin-right: 20px;">服务时间</span>
              <el-time-picker
                is-range
                v-model="form.value1"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围">
              </el-time-picker>
            </div>
          </div>
        </el-row>

      </el-form>
    </el-card>

    <div style="text-align: center;margin-top: 30px;">
      <el-button style="background: #ffffff!important;border-color: #dcdce0!important;color: #242832!important;width: 240px!important;margin-right: 20px;" @click="returnPage">取消</el-button>
      <el-button style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;width: 240px!important;">提交</el-button>
    </div>

  </div>
</template>

<script>

export default {
  props: {
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      value: [],
      options: [{
        value: 'zhinan',
        label: '指南',
        children: [{
          value: 'shejiyuanze',
          label: '设计原则',
          children: [{
            value: 'yizhi',
            label: '一致'
          }, {
            value: 'fankui',
            label: '反馈'
          }, {
            value: 'xiaolv',
            label: '效率'
          }, {
            value: 'kekong',
            label: '可控'
          }]
        }, {
          value: 'daohang',
          label: '导航',
          children: [{
            value: 'cexiangdaohang',
            label: '侧向导航'
          }, {
            value: 'dingbudaohang',
            label: '顶部导航'
          }]
        }]
      }]

    };
  },
  watch: {
    // data() {
    //   if (this.data) {
    //     console.log(this.data)
    //     this.form = Object.assign({}, this.data);
    //     this.isUpdate = true;
    //   } else {
    //     this.form = {};
    //     this.isUpdate = false;
    //   }
    // }
  },
  mounted() {
    if (this.data) {
      console.log(this.data)
      this.form = Object.assign({}, this.data);
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
        } else {
          return false;
        }
      });
    },

    //点击返回按钮
    returnPage(){
      this.$emit('showDetails',false);
    },

    //选择区域
    handleChange(value) {
      console.log(value);
    },


  },

}
</script>

<style scoped lang="scss">

</style>
